@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

* {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  cursor: none;
}

.cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  border: 0px solid #333;
  border-radius: 50%;
  background-color: #52CFE4;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
}